html,body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
body {
    font-family: 'Montserrat', sans-serif;
    color: var(--primary) !important;
}
nav {    
    background-color: var(--secondary) !important;
    border-bottom: 10px solid var(--primary) !important;
}
main {
    padding-top: 4.8rem;
}
h1,h2 {
    color: var(--primary) !important;
}
h1 {
    font-size: 1.25rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
}
h2 {
    font-weight: bold;
    font-size: 1.03rem;
    text-align: center;
}
h3 {
	font-size: 1.3rem;
    font-weight: 800;
	margin-bottom: 2rem;
}
.btn-primary {
    color: var(--primarytext) !important;
    background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}
.btn-secondary {
    color: var(--secondarytext) !important;
    background-color: var(--secondary) !important;
	border-color: var(--secondary) !important;
}
.btn-primary:hover {
    color: var(--primarytext) !important;
    background-color: var(--primarydarker) !important;
    border-color: var(--primarydarker) !important;
}
.btn-primary:focus, .btn-primary.focus {
    color: var(--primarytext) !important;
    background-color: var(--primarydarker) !important;
    border-color: var(--primarydarker) !important;
    box-shadow: 0 0 0 0.2rem var(--primarytranslucent) !important;
}
.btn-secondary:hover {
    color: var(--secondarytext) !important;
    background-color: var(--secondarydarker) !important;
    border-color: var(--secondarydarker) !important;
}
.btn-secondary:focus, .btn-secondary.focus {
    color: var(--secondarytext) !important;
    background-color: var(--secondarydarker) !important;
    border-color: var(--secondarydarker) !important;
    box-shadow: 0 0 0 0.2rem var(--secondarytranslucent) !important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: var(--primarytext) !important;
    background-color: var(--primarydarkest) !important;
    border-color: var(--primarydarkest) !important;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: var(--secondarytext) !important;
    background-color: var(--secondarydarkest) !important;
    border-color: var(--secondarydarkest) !important;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}
.custom-control-input:checked~.custom-control-label:before {
    color: var(--primarytext) !important;
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
}
.dropdown-menu {
    position: absolute !important;
}
.form-group label {
    text-transform: uppercase;
    font-weight: bold;
    font-size: .8rem;
}
.form-group .custom-switch label {
    text-transform: none;
    font-weight: 500;
}
.form-control {
    color: var(--primary) !important;
	border-color: initial;
    text-align: center;
}
.btn-primary.form-control {
	color: var(--primarytext) !important;
}
.btn-secondary.form-control {
	color: var(--secondarytext) !important;
}
textarea.form-control {
	border-color: #4C5165;
    text-align: left;
}    
.background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #777777;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
}
nav.navbar {
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 2;
}
.navbar-brand {
    margin: auto;
    padding: 0;
}
.navbar-brand img {
    height: 3rem;
}
.iframe-container {
    height: 0;
    padding-top: calc(100% * 9 / 16);
    margin: 0 15px;
    max-width: calc(100% - 30px);
}
.iframe-container iframe {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

a.close[data-target="#questionModal"] svg path {
	fill: var(--primary) !important;
}
#navbarTitle {
    font-size: 1.3rem;
}
#col-left {
    padding-top: 0.3rem;
}
@media(min-width: 375px) {
    h1 {
        font-size : 2.5rem;
    }
    h2 {
        font-size: 1.43rem;
    }
}
@media(min-width: 576px) {
    h1 {
        font-size: 2.25rem;
    }
    h2 {
        font-size :1.13rem;
    }
}
@media(min-width: 768px) {
    .custom-switch .custom-control-label:before, .custom-switch .custom-control-label:after {
        top: 50%;
        transform: translateY(-50%);
    }
    .custom-switch .custom-control-input:checked~.custom-control-label:after {
        transform: translateX(.75rem) translateY(-50%);
    }
}
@media(min-width: 992px) {
    h1 {
        font-size: 3rem;
        text-align: left;
    }
    h2 {
        font-size :1.5rem;
        text-align: left;
    }
    main {
        padding-top: 7.73rem;
    }
}
@media(min-width: 992px) and (orientation: landscape) {
    .full-height-lg {
        height: 100%;
    }
    .navbar-brand {
        margin: 0;
    }
    .navbar-brand img {
        height: 6.13rem;
    }
	.openQuestion-holder {
	    position: fixed;
	}
}
@media(min-width: 992px) and (orientation: portrait) {
    .col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .full-height-lg {
        height: auto;
    }
}
@media(min-width: 1200px) {
	h1 {
		font-size: 3.5rem;
	}
}
@media(min-width: 1400px) {
    h1 {
        font-size: 4rem;
    }
    nav>.navbar-brand {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    #col-left {
        padding-top: 7.5rem;
    }
    #navbarTitle {
        padding-right: 8rem;
    }
}